import $ from 'jquery'
import Util from 'bootstrap/js/src/util'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME                = 'tileslide'
const VERSION             = '0.0.1'
const DATA_KEY            = 'bs.tileslide'
const EVENT_KEY           = `.${DATA_KEY}`
const DATA_API_KEY        = '.data-api'
const JQUERY_NO_CONFLICT  = $.fn[NAME]

const Default = {
  interval        : 5000,
  activeItemWidth : '.4',
  control         : 'hover',
  wrap            : true,
}

const DefaultType = {
  interval        : '(number|boolean)',
  activeItemWidth : '(string|boolean)',
  control         : '(string|boolean)',
  wrap            : 'boolean',
}

const Event = {
  SLIDE          : `slide${EVENT_KEY}`,
  MOUSEENTER     : `mouseenter${EVENT_KEY}`,
  MOUSELEAVE     : `mouseleave${EVENT_KEY}`,
  LOAD_DATA_API  : `load${EVENT_KEY}${DATA_API_KEY}`
}

const ClassName = {
  TILESLIDE : 'tileslide',
  ACTIVE    : 'active',
  ITEM      : 'tileslide-item',
}

const Selector = {
  ACTIVE      : '.active',
  ACTIVE_ITEM : '.active.tileslide-item',
  ITEM        : '.tileslide-item',
  DATA_RIDE   : '[data-ride="tileslide"]'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class Tileslide {
  constructor(element, config) {
    this._interval = null
    this._isPaused = false
    this._config = this._getConfig(config)
    this._element = element

    this._addEventListeners()
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  // Public

  init(event) {
    this._items = this._element
      ? [].slice.call(this._element.querySelectorAll(Selector.ITEM))
      : []
    $.each(this._items, (index, item) => {
      if ($(item).hasClass(ClassName.ACTIVE)) {
        $(item).css('flex-grow', this._getActiveItemGrow())
      }
    })
  }

  pause(event) {
    if (!event) {
      this._isPaused = true
    }

    clearInterval(this._interval)
    this._interval = null
  }

  cycle(event) {
    if (!event) {
      this._isPaused = false
    }

    if (this._interval) {
      clearInterval(this._interval)
      this._interval = null
    }

    if (this._config.interval && !this._isPaused) {
      this._interval = setInterval(
        this._slide.bind(this), this._config.interval
      )
    }
  }

  dispose() {
    $(this._element).off(EVENT_KEY)
    $.removeData(this._element, DATA_KEY)

    this._items    = null
    this._config   = null
    this._element  = null
    this._interval = null
    this._isPaused = null
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...config
    }
    Util.typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  _addEventListeners() {
    $(this._element)
      .on(Event.MOUSEENTER, (event) => this.pause(event))
      .on(Event.MOUSELEAVE, (event) => this.cycle(event))
    if (this._config.control === 'hover') {
      this._items = this._element
        ? [].slice.call(this._element.querySelectorAll(Selector.ITEM))
        : []
	  $(this._items).on(Event.MOUSEENTER, (event) => {
		  this._slide($(event.target).closest(Selector.ITEM))
	  })
    }
  }

  _getActiveItemGrow() {
    this._items = this._element
      ? [].slice.call(this._element.querySelectorAll(Selector.ITEM))
      : []

    const itemCountMinusOne = this._items.length - 1;
    const activeItemWidth = parseFloat(this._config.activeItemWidth)
    return Math.round(2 * itemCountMinusOne * activeItemWidth)
  }

  _getItemIndex(element) {
    this._items = element && element.parentNode
      ? [].slice.call(element.parentNode.querySelectorAll(Selector.ITEM))
      : []

    return this._items.indexOf(element)
  }

  _getNextItem(activeElement) {
    const activeIndex   = this._getItemIndex(activeElement)
    const lastItemIndex = this._items.length - 1
    const isGoingToWrap = activeIndex === lastItemIndex

    if (isGoingToWrap && !this._config.wrap) {
      return activeElement
    }

    return this._items[(activeIndex + 1) % this._items.length]
  }

  _triggerSlideEvent(relatedTarget) {
    const targetIndex = this._getItemIndex(relatedTarget)
    const fromIndex = this._getItemIndex(this._element.querySelector(Selector.ACTIVE_ITEM))
    const slideEvent = $.Event(Event.SLIDE, {
      relatedTarget,
      from: fromIndex,
      to: targetIndex
    })

    $(this._element).trigger(slideEvent)

    return slideEvent
  }

  _slide(element) {
    const activeElement = this._element.querySelector(Selector.ACTIVE_ITEM)
    const activeElementIndex = this._getItemIndex(activeElement)
    const nextElement   = element || activeElement && this._getNextItem(activeElement)
    const nextElementIndex = this._getItemIndex(nextElement)

    if (nextElement && $(nextElement).hasClass(ClassName.ACTIVE)) {
      return
    }

    const slideEvent = this._triggerSlideEvent(nextElement)
    if (slideEvent.isDefaultPrevented()) {
      return
    }

	if (!activeElement || !nextElement) {
		// Some weirdness is happening, so we bail
		return
	}

    $(activeElement)
      .removeClass(ClassName.ACTIVE)
      .css('flex-grow', '')
    $(nextElement)
      .addClass(ClassName.ACTIVE)
      .css('flex-grow', this._getActiveItemGrow())
  }

  // Static

  static _jQueryInterface(config) {
    return this.each(function () {
      let data = $(this).data(DATA_KEY)
      let _config = {
        ...Default,
        ...$(this).data()
      }

      if (typeof config === 'object') {
        _config = {
          ..._config,
          ...config
        }
      }

      if (!data) {
        data = new Tileslide(this, _config)
        $(this).data(DATA_KEY, data)
      }

      if (_config.interval && _config.ride) {
        data.init()
        data.pause()
        data.cycle()
      }
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

$(window).on(Event.LOAD_DATA_API, () => {
  const tileslides = [].slice.call(document.querySelectorAll(Selector.DATA_RIDE))
  for (let i = 0, len = tileslides.length; i < len; i++) {
    const $tileslide = $(tileslides[i])
    Tileslide._jQueryInterface.call($tileslide, $tileslide.data())
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME] = Tileslide._jQueryInterface
$.fn[NAME].Constructor = Tileslide
$.fn[NAME].noConflict = () => {
  $.fn[NAME] = JQUERY_NO_CONFLICT
  return Tileslide._jQueryInterface
}

export default Tileslide
